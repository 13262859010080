.textarea {
  width: 100%;
  min-height: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 14px;
  background-color: #f8f8f8;
  overflow: auto;
  resize: none;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}
